import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";
import Home from "../../views/Home/NewHome";
import PhaseMenu from "../Home/PhaseMenu";
import LeftMenu from "../Home/LeftMenu";
import NewLandingPage from "../LandingPage/NewLandingPage";

import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useDispatch } from "react-redux";
import {
  getByEmailStart,
  getByEmailSuccess,
  getByEmailFail,
} from "../../store/Action";
import { useAxios } from "../../context/AxiosContext";

import "./PageContent.css";
import RealizeValueLandingPage from "../LandingPage/RealizeValueLandingPage";
import MCEMForISLandingPage from "../LandingPage/MCEMForISLandingPage";

initializeIcons();
function PageContent() {
  useEffect(() => {
    getByEmail();
  }, []);
  const [isProjectDelivery, setIsProjectDelivery] = useState();
  const [isTopPhaseMenu, setIsTopPhaseMenu] = useState(true);
  const [selected, setSelected] = useState(false);
  const [dynamicPhaseCSS, setDynamicPhaseCSS] = useState("");
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false);
  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const { axiosGetService } = useAxios();
  const dispatch = useDispatch();
  const getByEmail = async () => {
    dispatch(getByEmailStart());
    axiosGetService("/api/sdmuser/getbyemail")
      .then((response) => {
        dispatch(getByEmailSuccess(response));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getByEmailFail());
      });
  };

  const getProjectDeliveryId = async () => {
    await axiosGetService(
      `api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`
    ).then((response) => {
      setIsProjectDelivery(response.data[0].value.split(",").at(1));
    });
  };
  const checkZoomLevel = () => {
    const zoomLevel = (window.outerWidth / window.innerWidth) * 100;
    setIsZoomed(zoomLevel >= 200);
    if (zoomLevel >= 200) {
      setIsMenuVisible(false);
    }else{
      setIsMenuVisible(true);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', checkZoomLevel);
    checkZoomLevel();
    
    return () => {
      window.removeEventListener('resize', checkZoomLevel);
    };
  }, []);
  useEffect(() => {
    getProjectDeliveryId();
  }, []);
  const rightSideMainContainer = {
    width: isMenuVisible ? 'calc(100vw - 200px)' : '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
  };
  return (
    <div>
      <div class="navHeading">
        <p>
          MCEM connects all MCAPS roles as one team, bringing together the best
          of what Microsoft has to offer to our customers through a unified
          engagement model that puts our customer's outcomes at the center
        </p>
      </div>
      <div role="main" className="mainContainer newHomeMainContainer">
      {isZoomed && (
        <div tabIndex="0" onClick={toggleMenuVisibility} className="leftMenuExpand">
          <svg fill="#000000" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g data-name="Layer 2">
            {isMenuVisible ? (
              <g data-name="arrow-forward">
              <rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/>
              <path d="M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z" fill="white"/>
              </g>
            ) : (
              <g data-name="arrow-back">
              <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
              <path d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z" fill="white"/>
              </g>
            )}
           </g>
          </svg>
        </div>
      )}

    {isMenuVisible && (
      <LeftMenu
        isTopPhaseMenu={isTopPhaseMenu}
        setIsTopPhaseMenu={setIsTopPhaseMenu}
        setSelected={setSelected}
        selected={selected}
      />
    )}
      <div style={rightSideMainContainer}>
        <PhaseMenu
          isTopPhaseMenu={isTopPhaseMenu}
          setIsTopPhaseMenu={setIsTopPhaseMenu}
          setSelected={setSelected}
          selected={selected}
          setDynamicPhaseCSS={setDynamicPhaseCSS}
          dynamicPhaseCSS={dynamicPhaseCSS}
        />
        <div className="rightSecContainer newHomePageContainer">
          <Switch>
            <Route exact path="/">
              <Home
                isTopPhaseMenu={isTopPhaseMenu}
                setIsTopPhaseMenu={setIsTopPhaseMenu}
                setSelected={setSelected}
                selected={selected}
                setDynamicPhaseCSS={setDynamicPhaseCSS}
                dynamicPhaseCSS={dynamicPhaseCSS}
              />
            </Route>
            <Route exact path={`/project/:projectTypeId(0|1|2)`}>
              <Home
                isTopPhaseMenu={isTopPhaseMenu}
                setIsTopPhaseMenu={setIsTopPhaseMenu}
                setSelected={setSelected}
                selected={selected}
                setDynamicPhaseCSS={setDynamicPhaseCSS}
                dynamicPhaseCSS={dynamicPhaseCSS}
              />
            </Route>
            {parseInt(window.location.href.split("/").at(6), 10) ===
              parseInt(isProjectDelivery, 10) &&
              window.location.href.split("/").at(7) === "phase" ? (
              (!isTopPhaseMenu ?
                <Route
                  path={`/project/:projectTypeId(0|1|2)/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhase`}
                >
                  <MCEMForISLandingPage
                    isTopPhaseMenu={isTopPhaseMenu}
                  />
                </Route>
                :
                <Route
                  path={`/project/:projectTypeId(0|1|2)/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhase`}
                >
                  <NewLandingPage />
                </Route>
              )

            ) : parseInt(window.location.href.split("/").at(6), 10) !==
              parseInt(isProjectDelivery, 10) ? (
              <Route
                exact
                path="/project/:projectTypeId(0|1|2)/method/:solutionMethodId"
              >
                <RealizeValueLandingPage />
              </Route>
            ) : (
              <Route
                exact
                path="/project/:projectTypeId(0|1|2)/method/:solutionMethodId"
              >
                <MCEMForISLandingPage />
              </Route>
            )}
            {parseInt(window.location.href.split("/").at(6), 10) !==
              parseInt(isProjectDelivery, 10) && (
                <Route
                  path={`/project/:projectTypeId(0|1|2)/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId`}
                >
                  <RealizeValueLandingPage />
                </Route>
              )}

            <Route path="*">
              <ComingSoon />
            </Route>
          </Switch>
        </div>
      </div>
        </div>
    </div>
  );
}

const ComingSoon = () => (
  <h2 className="pageNotFound">
    <b>404 Page not found!</b>
  </h2>
);

export default PageContent;
