import React, { useState, useEffect,useCallback  } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const MultiSelectDropdown = ({
	className,
	enableDropdown,
	data,
	complexityId,
	updatedValue,
	setUpdatedValue,
	type,
	pageType = undefined,
}) => {
	const [visible, setVisible] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [filteredData, setFilteredData] = useState([...data]);

	const searchRoles = useCallback(() => {
		if (searchValue.trim().length !== 0) {
			const filterRoles = data.filter(({ name }) =>
				name.toLowerCase().includes(searchValue.toLowerCase())
			);
			setFilteredData(filterRoles);
		} else {
			setFilteredData([...data]);
		}
	}, [searchValue, data]);

	const dropdownCollapse = (e) => {
		const dropdown = className.startsWith('.')
			? document.querySelector(className)
			: document.getElementById(className);	
		if (dropdown && !dropdown.contains(e.target)) {
			setVisible(false);
		}
	};
	

	useEffect(() => {
		searchRoles();
	}, [data, searchRoles]);

	useEffect(() => {
    window.addEventListener('click', dropdownCollapse);
    return () => {
        window.removeEventListener('click', dropdownCollapse);
    };
}, [dropdownCollapse]); // Add dropdownCollapse to the dependency array


	const handleRoleCheckBox = (role) => {
		setUpdatedValue((prevState) => {
			const complexityData = prevState[complexityId] || {};
			const roleList = complexityData[type] || [];

			let updatedData;
			if (roleList.find(({ id }) => id === role.id)) {
				updatedData = roleList.filter(({ id }) => id !== role.id);
			} else {
				updatedData = [...roleList, role];
			}

			return {
				...prevState,
				[complexityId]: {
					...complexityData,
					[type]: updatedData,
				},
			};
		});
	};

	const roleSelected = (roleId) =>
		(updatedValue[complexityId]?.[type] || []).some(({ id }) => id === roleId);

	// Prevent event propagation when clicking inside the dropdown
	const handleDropdownClick = (e) => {
		e.stopPropagation();
	};
	const styles = {
		iconWrapper: {
		  width: '40px',
		  height: '40px',
		  borderRadius: '50%',
		  backgroundColor: '#e6f0ff', // Light blue background
		  display: 'flex',
		  justifyContent: 'center',
		  alignItems: 'center',
		  border: '1px solid #b3d1ff', // Optional border for better contrast
		},
		icon: {
		  fontSize: '20px',
		  color: '#000', // Icon color
		},
	  };

	return (
		<div title={enableDropdown ? className : ''} onClick={handleDropdownClick}>
			<div className="editdropdown-container">
				<input
					className="editdropdown-input placeholderraci"
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					onFocus={() => {
						setVisible(true);
					}}
					placeholder={
						(updatedValue[complexityId]?.[type] || []).length > 0
							? `${(updatedValue[complexityId]?.[type] || []).length} selected`
							: 'Type something to search or select from list'
					}
				/>
				<div className="editicon-container">
					<Icon
					  style={{ fontWeight: pageType==='Activity' ? 'Bolder':'' ,fontSize: pageType==='Activity' ? '13px':'',marginLeft: pageType==='Activity' ? '0.5rem':''}}
						iconName="ChevronDown"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13 && enableDropdown) {
								setVisible(!visible);
							}
						}}
						onClick={() => setVisible(!visible)}
						role="button"
						className="mr-2"
					/>
					{/* <span className='Dropdownraciicon mr-1' tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13 && enableDropdown) {
								setVisible(!visible);
							}
						}}
						onClick={() => setVisible(!visible)}
						role="button"
						></span> */}
					<span className="Racisearchicon" aria-hidden="true"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								searchRoles();
							}
						}}
						onClick={searchRoles}
						role="button"></span>
					{/* <Icon
					style={styles.icon}
						iconName="Search"
						aria-hidden="true"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								searchRoles();
							}
						}}
						onClick={searchRoles}
						role="button"
					/> */}
					
					<span></span>
				</div>
				<div>
					<div
						className="editdropDownList"
						style={{ display: visible ? 'block' : 'none' }}
					>
						<ul>
							{filteredData.map((role) => (
								<li title={role.name} key={`${role.id}`}>
									<label className={
												`${pageType === 'Activity' ? 'NotificationChkboxContainer racidropdownnew' : 'NotificationChkboxContainer'}`
											}>
										<input
											name={role.name}
											type="checkbox"
											checked={roleSelected(role.id)}
										/>
										<span
											className={
												`${pageType === 'Activity' ? 'CustomCheckmark acitivityracicheckbox' : 'CustomCheckmark'}`
											}
											onClick={() => handleRoleCheckBox(role)}
											tabIndex={0}
											onKeyPress={(e) => {
												if (e.which === 13) {
													handleRoleCheckBox(role);
												}
											}}
											role="button"
										></span>
										<span
											className={
												`${pageType === 'Activity' ? 'complexityLabel activityracilabel' : 'complexityLabel'}`
											}
										>{role.name}</span>
									</label>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultiSelectDropdown;

