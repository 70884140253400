import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@fluentui/react';
import { hashtagValidator } from '../../helpers/Validation';
import '../Dropdown/Dropdown.css';
import '../MultiSelectDropdown/MultiSelectDropdown.css';

const HashtagSuggestions = ({
	data,
	className,
	getHashtag,
	searchValue,
	enableDropdown,
	setSearchValue,
	existingHashtags,
	setExistingHashtags,
	setHashtagSuggestions,
	submitHashtags,
	closeHashtagForm,
	userAddedHashtags,
	setUserAddedHashtags,
	setHashtagSuggestionInput,
	autoFocus,
	showSubmitButton,
	pageType,
	setUpdateApiCall,
	hashtagError,
	setHashtagError,
}) => {
	const [visible, setVisible] = useState(false);
	
	const dropdownCollapse = (e) => {
		const dropdown = document.getElementById(className);
		if (!dropdown.contains(e.target)) {
			setVisible(false);
		}
	};
	
	const selectHashtagFromSuggestions = async (item) => {
		const updatedHashtag = existingHashtags.filter((hashTag) => hashTag === item);
		if (updatedHashtag.length === 0) {
			setExistingHashtags([item, ...existingHashtags]);
			setHashtagSuggestions([]);
			setSearchValue('');
			setVisible(false);
		}
	};

	const inputFocus = useRef(null);
	const removeDuplicateHashtag = (data) => {
		return Array.from(new Set(data));
	  };
	const removeUserAddedHashtag = (tag) => {
		setUserAddedHashtags(userAddedHashtags.filter((item) => tag !== item));
	};
	const addHashtags = (item, type) => {
		const addedHashtag = type === 'fromSuggestionList'
		  ? item.split('#')
		  : searchValue.trim().split('#');
		const updatedHashtag = addedHashtag
		  .map((item) => '#' + item)
		  .filter(
			(hashTag) =>
			  !existingHashtags.includes(hashTag) &&
			  !userAddedHashtags.includes(hashTag) &&
			  hashTag.trim().length > 1
		  );
		if (updatedHashtag.length > 0) {
		  setUserAddedHashtags([
			...userAddedHashtags,
			...removeDuplicateHashtag(updatedHashtag),
		  ]);
		  setUpdateApiCall(true);
		}
		setSearchValue('');
		setHashtagSuggestionInput('');
	  };
	
	useEffect(() => {
		if (searchValue.length > 0) {
		  const hashTags = searchValue
			.trim()
			.split('#')
			.map((item) => '#' + item);
		  setHashtagError(hashTags.some(hashTag => !hashtagValidator.test(hashTag) || hashTag.length > 19));
		  setHashtagSuggestionInput(hashTags.length > 0 ? hashTags[hashTags.length - 1] : '');
		} else {
		  setHashtagError(false);
		  setHashtagSuggestionInput('');
		}
	  }, [searchValue]);

	useEffect(() => {
		if (data.length > 0) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [data]);

	useEffect(() => {
		window.addEventListener('click', dropdownCollapse);
		return () => {
			window.removeEventListener('click', dropdownCollapse);
		};
	}, []);

	return (
		<div id={className} className='hashTagsWithCancelCheckMark'>
			<div>
				<div
					className={`${pageType==='activityEdit'? 'activityEditHashtagsInput' :'dropDowncontainer hashtagsDropDownContainerWithTags'} ${
						enableDropdown ? '' : 'disableDropdown'
					} `}
					tabIndex={0}
					onClick={() => {
						inputFocus.current && inputFocus.current.focus();
						setVisible(true);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							inputFocus.current && inputFocus.current.focus();
							setVisible(true);
						}
					}}
					aria-label={`Click Enter to add Hashtag`}
					role="textbox"
					aria-expanded={visible ? 'true' : 'false'} 
					aria-controls="hashtagDropdownList"
				>
					<div 
					className={`${pageType==='activityEdit'? 'hashTagsContaineredit' :'hashTagsContainer'}`}>
						{userAddedHashtags.length > 0 ? (
							<>
								{userAddedHashtags.map((item) => (
									<span key={item} aria-label={`${item.substring(1)} Hashtag`} tabIndex={0}>
										{item}{' '}
										<Icon
											iconName="Cancel"
											onClick={() => {
												removeUserAddedHashtag(item);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													removeUserAddedHashtag(item);
												}
											}}
											tabIndex={0}
											role="button"
											aria-label={`Remove ${item.substring(1)} Hashtag`}
										/>
									</span>
								))}
							</>
						) : (
							<></>
						)}
						<div className="hashtagAutoSuggestions" >
							<input
								role="textbox"
								onChange={(e) => {
									setSearchValue(e.target.value.trim());
									inputFocus.current && inputFocus.current.focus();
								}}
								value={searchValue}
								placeholder={
									userAddedHashtags.length === 0 ? (pageType === 'activityEdit'?'Please add space separated #hashtag' :'enter #Hashtags' ): ''
								}
								aria-label="Add hashtags"
								onKeyPress={(e) => {
									if ((e.which === 13 || e.which === 32) && !hashtagError) {
										addHashtags(null, 'inputBoxHashtag');
									}
								}}
								onFocus={() => setVisible(true)}
								autoFocus={autoFocus}
								ref={inputFocus}
							/>
							<div>
								{data.length > 0 ? (
									<div className="dropDownList hashtagDpList" style={{ display: visible ? 'block' : '' }} id="hashtagDropdownList">
										<ul> 
											{data.map((item) => {
												if (item.type === 'header') {
													return (
														<li className="dropdownHeader" title={item.name + ' Header'} key={item.id}>
															<span>{item.name}</span>
														</li>
													);
												}

												return (
													<li title={item.name} key={item.id}>
														<span
															className="complexityLabel"
															onClick={() => {
																addHashtags(item.name, 'fromSuggestionList');
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	addHashtags(item.name, 'fromSuggestionList');
																}
															}}
															tabIndex={0}
															title={item.name}
															aria-label={`Click to add ${item.name} as Hashtag`}
														>
															{item.name}
														</span>
													</li>
												);
											})}
										</ul>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</div>
			
			</div>
			{hashtagError ? (
                <p className=  {pageType === 'topics' ?"errorMsg mb-0":"errorMsg"}>
                    Hashtag should be combination of letters, numbers and should not
                    exceed 18 characters
                </p>
            ) : (<>
				{pageType !== 'activityEdit' && (
					<p className={pageType === 'topics' ? "mb-0" : ""}>
					  Please add space separated hashtag
					</p>
				  )}</>
				  
            )}
		</div>
	);
};

export default HashtagSuggestions;
