import React, { useState, useEffect } from 'react';
import { useParams, Link, useRouteMatch, useHistory } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import { Icon } from '@fluentui/react/lib/Icon';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import $ from 'jquery';

import { useAxios } from '../../context/AxiosContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { artifactMapData } from '../../static/ArtifactMapData';

// import TutorialsYammer from '../Yammer/TutorialsYammer';
import Breadcrumbs from '../Breadcrumbs';
import Spinner from '../../helpers/Spinner';
import FavoriteAndFeedback from '../FavoriteAndFeedback';
import RatingConfirmationModal from './RatingConfirmationModal';
import HashtagSuggestions from '../HashtagSuggestions';
import { TabTitle } from '../../utils/GeneralFunctions';
import './TopicsTutorialsDetails.css';

initializeIcons();

const TopicsDetailPage = ({
  // setExpandTopicsLeftMenu,
}) => {
  const history = useHistory();
  const { track } = useAppInsights();
  const { url } = useRouteMatch();
  const { axiosGetService, axiosPostService, userAlias } = useAxios();
  const { tutorialType, tutorialId } = useParams();
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId, endUserView } =
    useLeftMenuCollapse();
  const [consultingTutorials, setConsultingTutorials] = useState(null);
  const [tutorialsDescription, setTutorialsDescription] = useState(null);
  const [showMenuButton, setShowMenuButton] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [topicsType, selectedTopicsListId] = url.split("/").slice(3);
  const [supportTutorials, setSupportTutorials] = useState(null);
  const sortData = [
    { id: 1, name: 'Name', type: 'Name' },
    { id: 2, name: 'Publish Date', type: 'PublishDate' },
    { id: 3, name: 'Rating', type: 'Rating' },
    { id: 4, name: 'Views', type: 'Views' },
  ];
  const [selectedSortType, setSelectedSortType] = useState({
    id: 1,
    name: 'Name',
  });
  const [searchInput, setSearchInput] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [enableRating, setEnableRating] = useState(false);
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [showRatingConfirmationPopUp, setShowRatingConfirmationPopUp] =
    useState(false);
  const [showHashtagsForm, setShowHashtagsForm] = useState(false);
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [hashtagsInput, setHashtagsInput] = useState('');
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagError, setHashtagError] = useState(false);
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [updateApiCall, setUpdateApiCall] = useState(false);

  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const submitHashtags = async () => {
    if (userAddedHashtags.length > 0) {
      const postData = {
        artifactTypeId: artifactMapData['tutorials'],
        artifactId: tutorialId,
        hashtags: [...userAddedHashtags, ...existingHashtags].toString(),
      };
      await axiosPostService(`api/sdmhashtags/add`, postData).then(
        (response) => {
          if (response.data.status) {
            if (userAddedHashtags.length > 0) {
              track(1, 1203, tutorialId, tutorialsDescription.name, {
                hashtags: userAddedHashtags.toString(),
              });
            }
            setExistingHashtags([...userAddedHashtags, ...existingHashtags]);
            setHashtagsInput('');
            setHashtags([]);
            setHashtagSuggestionInput('');

            setUserAddedHashtags([]);
          } else {
            toast.error(response.data.errorMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const getUserDetails = async (email) => {
    const accessToken = await axiosTokenService();
    const userData = await getOtherUserProfile(accessToken, email);
    setUserDetails(userData);
  };

  // api call to get the request topic details
  const getTutorialsDescription = async () => {
    await axiosGetService(
      `api/sdmtutorials/videoTutorials/getbyid?videoTutorialId=${tutorialId}`
    ).then((response) => {
      setTutorialsDescription(response.data);
      getUserDetails(response.data.uploadedEmail);
      setExistingHashtags([
        ...response.data.hashTagsList.map(({ name }) => {
          return name;
        }),
      ]);
      track(2, 1104, tutorialId, response.data.name);
    });
  };

  // call to api to get consulting or support topics data
  const getConsultingOrSupportTutorials = async () => {
    await axiosGetService(

      `api/sdmtutorials/videoTutorials/get?searchTitle=${searchInput ? encodeURIComponent(searchInput) : 0
      }&hashTagsText=0&sortBy=${selectedSortType.name}&filterIds=0&pageTypeId=11&endUserView=${endUserView}`
    ).then((response) => {
      setConsultingTutorials(response.data);
      if (searchInput) {
        track(1, 1106, tutorialId, searchInput, 'Tutorials');
      }
    });
  };

  // load and set the breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${tutorialId}&itemTypeId=81
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split('>')
        .map((item) => item.trim());
      const topicsBreadcrumb = {
        title: breadcrumbTitle[1],
        path: window.location.search
          ? `/topics${window.location.search}`
          : '/tutorials',
        parent: ['home', 'search'],
        type: 'topics',
      };
    
  
      const currentTopicsContentBreadcrumb = {
        title: breadcrumbTitle[3],
        parent: ['topicType'],
      };

      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === 'search' ? 2 : 1
      );
      newBreadcrumbs.push(topicsBreadcrumb);
      newBreadcrumbs.push(currentTopicsContentBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[3]);
    });
  };

  const updateTutorialViews = async () => {
    const postData = {
      id: tutorialId,
    };
    await axiosPostService(
      `api/sdmtutorials/videoTutorials/updateviews`,
      postData
    );
  };

  const updateRating = async (rating) => {
    setRatingSubmitted(true);
    const postData = {
      id: tutorialId,
      rating: rating,
    };
    await axiosPostService(
      `api/sdmtutorials/videoTutorials/insertRating`,
      postData
    ).then((response) => {
      if (response.data.status) {
        setEnableRating(false);
        setRatingSubmitted(false);
        getTutorialsDescription();
        track(1, 1105, tutorialId, tutorialsDescription.name);
      } else {
        setRatingSubmitted(false);
      }
    });
  };

  const toggleTopicMenuIcon = () => {
    setShowMenuButton(!showMenuButton);
  };

  const collapseFilter = (e) => {
    if (
      e.target.className === 'reRating btn btn-primary' ||
      e.target.className === 'star-container'
    ) {
      return;
    } else if (
      !e.target.className ||
      (typeof e.target.className !== 'object' &&
        e.target.className !== 'sampleTemplateRating')
    ) {
      setEnableRating(false);
    }
  };

  useEffect(() => {
    if (
      consultingTutorials !== null &&
      !selectedCategoryId
    ) {
      setDeafultCategoryId();
    }
  }, [consultingTutorials, selectedCategoryId]);

  const setDeafultCategoryId = () => {
    for (const category in consultingTutorials) {
      const defaultCategoryId = consultingTutorials[category].categoryId;
      for (const topic in consultingTutorials[category].sdmTutorials) {
        if (
          Number(consultingTutorials[category].sdmTutorials[topic].id) ===
          Number(selectedTopicsListId)
        ) {
          setSelectedCategoryId(defaultCategoryId);
          break;
        }
      }
    }
  };

  // function to set current category id when user clicks on topic details page
  const setCurrentCategoryId = (currentCategoryId) => {
    if (Number(currentCategoryId) === Number(selectedCategoryId)) {
      setSelectedCategoryId(-1);
    } else {
      setSelectedCategoryId(currentCategoryId);
    }
  };

  const getConsultingOrSupportSdmTopicsList = (topics) =>
    topics.map((item) => (
      <div className="topicsAccordianViewContainer" key={item.categoryId}>
        <div
          className="TopicsParentWrapper"
          tabIndex={0}
          role="listbox"
          onKeyPress={(e) => {
            if (e.which === 13) {
              setCurrentCategoryId(item.categoryId);
            }
          }}
          onClick={() => {
            setCurrentCategoryId(item.categoryId);
          }}
          title={item.categoryName}
          aria-roledescription="dropdown"
          aria-label={item.categoryName}
          aria-expanded={
            selectedCategoryId === item.categoryId ? "true" : "false"
          }
        >
          <Icon
            iconName="ChevronDown"
            className={
              selectedCategoryId === item.categoryId
                ? "ShowTopicsChildWrapper"
                : "DoNotShowTopicsChildWrapper"
            }
          />
          <Icon
            iconName="ChevronRightMed"
            className={
              selectedCategoryId !== item.categoryId
                ? "ShowTopicsChildWrapper"
                : "DoNotShowTopicsChildWrapper"
            }
          />
          <span className="TopicsTitleSpan" title={item.categoryName}>
            {item.categoryName}
          </span>
        </div>
        <div
          className={
            selectedCategoryId === item.categoryId
              ? "ShowTopicsChildWrapper"
              : "DoNotShowTopicsChildWrapper"
          }
        >

          <div className="TopicsChildWrapper">
            <ul>
              {item.sdmTutorials.map((sdmTutorials) => (
                <li
                  key={sdmTutorials.id}
                  className={
                    Number(selectedTopicsListId) === Number(sdmTutorials.id)
                      ? "ActiveLink"
                      : "NotActiveLink"
                  }
                >
                  <Link
                    className={
                      Number(selectedTopicsListId) === Number(sdmTutorials.id)
                        ? "ActiveLink"
                        : "NotActiveLink"
                    }
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        track(1, 10, sdmTutorials.id, sdmTutorials.name);
                      }
                    }}
                    onClick={() => {
                      track(1, 10, sdmTutorials.id, sdmTutorials.name);
                    }}
                    title={sdmTutorials.name}
                    to={
                      window.location.search
                        ? `/tutorials/${sdmTutorials.id + window.location.search}`
                        : `/tutorials/${sdmTutorials.id}`
                    }
                  >
                    {sdmTutorials.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));


  useEffect(() => {
    window.addEventListener('click', collapseFilter);
    return () => {
      window.removeEventListener('click', collapseFilter);
    };
  }, []);

  useEffect(() => {
    loadBreadcrumb();
    setEnableRating(false);
  }, [tutorialId]);

  useEffect(() => {
    getTutorialsDescription();
  }, [tutorialId]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getConsultingOrSupportTutorials();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [searchInput, selectedSortType, endUserView]);

  useEffect(() => {
    setSkipNavigationId('topicsMenu');
    // setExpandTopicsLeftMenu(false);
  }, []);

  useEffect(() => {
    updateTutorialViews();
  }, [tutorialId]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);
  $('.star-container').find('svg').each(function(index) {
    $(this).attr('id', index+1);
    $(this).attr('tabindex', '0');
    var floatValue = parseFloat($('.star-ratings').attr('title').split(' ')[0]);
    $(this).attr('aria-label', 'Click star to rate '+ ((index + 1) <= floatValue ? 'selected ' : (index + 1)-floatValue<1?'half selected ':'not selected ') + (index + 1) + ' of 5');
      
    $(this).on('keypress', function(event) {
      if (event.keyCode === 32 || event.keyCode === 13) {
        handleEnterOrSpaceKey(parseInt($(this).attr('id')));
      }
    });
  });
  
  const handleEnterOrSpaceKey = (rating) => {
    if (tutorialsDescription.isRated &&!enableRating ) {
      setShowRatingConfirmationPopUp(true);
      updateRating(rating)
    } 
    else if (userAlias !== tutorialsDescription.uploadedEmail ) {
      setEnableRating(true);
      updateRating(rating);
    }
  };
  return (
    <div className="topicLandingMainContainer">
      <div className="topicsLandingPage">
        <Breadcrumbs />
        <FavoriteAndFeedback />
        <div className="topicsCrowdSourcingContainer tutorialContainer">
          <div className="topicsLeftAndMainContainer" role="main">
            {!tutorialsDescription ? (
              <Spinner />
            ) : (
              <div className="topicsRightMainContainerVideo">
                <div className="topicsAccordianList">
                  <div
                    className="topicsMenu"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        toggleTopicMenuIcon();
                      }
                    }}
                  >
                    {showMenuButton ? (
                      <>
                        <div
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              toggleTopicMenuIcon();
                            }
                          }}
                          onClick={toggleTopicMenuIcon}
                          className={
                            showMenuButton
                              ? "topicsMenuCollapsedHamberger"
                              : "showHamberger"
                          }
                          title="Tutorials menu"
                          aria-label="Click to Expand Tutorials menu"
                          aria-expanded="false"
                          role="button"
                        >
                          <Icon iconName="GlobalNavButton" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              toggleTopicMenuIcon();
                            }
                          }}
                          onClick={toggleTopicMenuIcon}
                          className="topicsLeftMenuCollapseIcon"
                          title="Tutorials menu"
                          aria-label="Click to Collapse Tutorials menu"
                          aria-expanded="true"
                          role="button"
                        >
                          <Icon iconName="ChromeClose" />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      showMenuButton
                        ? "showHamberger"
                        : "showTopicsListLeftMenu"
                    }
                  >
                    <div className="listOfTopicsLeftMenu">
                      <div className="listOfTopicsMainContainer">
                        <div className="listOfTopicsMainContainer">
                          <>
                            {consultingTutorials
                              ? getConsultingOrSupportSdmTopicsList(consultingTutorials)
                              : null}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="topicsDescriptionDetails"
                  id="topicTextDescription"
                >
                  {tutorialsDescription ? (
                    <div className="topicDescriptionDiv tutorialDecsDiv">
                      <div className="topicTutorialVideosMainDiv">
                        <h1 className="totorialVideoTitle">
                          {tutorialsDescription.name}
                        </h1>
                        <div className="existingHashTagsOfTopic">
                          {!showHashtagsForm &&
                            existingHashtags.map((name) => (
                              <span
                                className="mr-1 hashTagsColor"
                                key={name}
                                onClick={() => {
                                  history.push(
                                    `/search?searchParam=${encodeURIComponent(
                                      name
                                    )}`
                                  );
                                }}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    history.push(
                                      `/search?searchParam=${encodeURIComponent(
                                        name
                                      )}`
                                    );
                                  }
                                }}
                                tabIndex="0"
                              >
                                {name}
                              </span>
                            ))}
                          {!showHashtagsForm ? (
                            <div className="addHashtagsIconContainer">
                              <span
                                className="addHashtagsIcon"
                                onClick={() => {
                                  setShowHashtagsForm(true);
                                }}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    setShowHashtagsForm(true);
                                  }
                                }}
                                tabIndex="0"
                                role="button"
                                aria-label="Add hashtags"
                                aria-expanded={showHashtagsForm ? true : false}
                              ></span>
                              <div className="tabsTitleToolTip">
                                <span className="tabsToolTipArrow"></span>
                                <span>Add Hashtags</span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {showHashtagsForm ? (
                          <div className="topicDetailsHasttagsContainer">
                            <div className="topicDetailsHasttagInput">
                              <HashtagSuggestions
                                className="topicsHashtagsInput"
                                enableDropdown={true}
                                data={hashtagSuggestions}
                                setUpdateApiCall={setUpdateApiCall}
                                setHashtagSuggestions={setHashtagSuggestions}
                                searchValue={hashtagsInput}
                                setSearchValue={setHashtagsInput}
                                existingHashtags={existingHashtags}
                                userAddedHashtags={userAddedHashtags}
                                setUserAddedHashtags={setUserAddedHashtags}
                                hashtagSuggestionInput={hashtagSuggestionInput}
                                setHashtagSuggestionInput={
                                  setHashtagSuggestionInput
                                }
                                closeHashtagForm={() => {
                                  setHashtagsInput("");
                                  setShowHashtagsForm(false);
                                  setUserAddedHashtags([]);
                                }}
                                submitHashtags={submitHashtags}
                                autoFocus={true}
                                showSubmitButton={true}
                              />
                            </div>

                            {existingHashtags.length > 0 ? (
                              <div className="hashTagsContainer">
                                {existingHashtags.map((item) => (
                                  <span key={item}>{item}</span>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="topicTutorialsVideoDiv">
                          <div className="tutorialsVideo">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${tutorialsDescription.url}`,
                              }}
                            />
                          </div>
                        </div>
                        <div className="topicTutorialsVideoDetailsSection">
                          <div className="totorialTitleDetails">
                            <div className="tutorialStarRating">
                            <div className="ratingWrapper">
                              <span
                                aria-label="Click star to rate"
                                className="sampleTemplateRating"
                                onClick={() => {
                                  if (
                                    tutorialsDescription.isRated &&
                                    !enableRating
                                  ) {
                                    setShowRatingConfirmationPopUp(true);
                                  } else if (
                                    userAlias !==
                                    tutorialsDescription.uploadedEmail
                                  ) {
                                    setEnableRating(true);
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (e.key=== 'Space' || e.key === 'Enter') {
                                  if (
                                    tutorialsDescription.isRated &&
                                    !enableRating
                                  ) {
                                    setShowRatingConfirmationPopUp(true);
                                  } else if (
                                    userAlias !==
                                    tutorialsDescription.uploadedEmail
                                  ) {
                                    setEnableRating(true);
                                  }}
                                }}
                              >
                                {ratingSubmitted ? (
                                  'Updating your rating...'
                                ) : enableRating ? (
                                  <StarRatings 
                                    rating={0}
                                    tabIndex={0}
                                    starDimension="19px"
                                    starRatedColor={'#ffd700'}
                                    starHoverColor={'#ffd700'}
                                    starEmptyColor={'#e9e9f0'}
                                    starSpacing="5px"
                                    svgIconViewBox="0 0 31.951 32"
                                    svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
                                    changeRating={(value) =>
                                      updateRating(value)
                                    }
                                  />
                                ) : (
                                  <>
                                  
                                    <StarRatings
                                      rating={tutorialsDescription.rating}
                                      starDimension="14px"
                                      tabIndex={0}
                                      starRatedColor={'#ffd700'}
                                      starEmptyColor={'#fff'}
                                      starSpacing="3px"
                                      svgIconViewBox="0 0 31.951 32"
                                      svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
                                    />
                                    <span>{`(${tutorialsDescription.ratingCount})`}</span>
                                  </>
                                )}
                              </span>
                              {userAlias !==
                                tutorialsDescription.uploadedEmail ? (
                                <p>Click star to rate</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          </div>
                          <div className="totorialUploaderDetails">
                            {Object.keys(userDetails).length > 0 ? (
                              <>
                                <div>
                                  <span>
                                    {!userDetails.photo.includes('ui-avatar')?(
                                    <img
                                      src={userDetails.photo}
                                      tabIndex={0}
                                      alt={`${userDetails.firstName} ${userDetails.surname} image`}
                                    />
                                    ):(
                                      <span 
                                      className='ownerImgVideo' 
                                      tabIndex={0}
                                      alt={`Image not found`}
                                      ></span>
                                    )}
                                  </span>
                                  <h4>
                                    {userDetails.firstName +
                                      ' ' +
                                      userDetails.surname}
                                  </h4>
                                </div>
                                <div className="totorialUploadPublishedDate">
                                  <span>Published On&nbsp;:&nbsp;</span>
                                  <span>
                                    {tutorialsDescription.publishedDate}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {showRatingConfirmationPopUp ? (
                  <RatingConfirmationModal
                    setEnableRating={setEnableRating}
                    showRatingConfirmationPopUp={showRatingConfirmationPopUp}
                    setShowRatingConfirmationPopUp={
                      setShowRatingConfirmationPopUp
                    }
                  />
                ) : (
                  <></>
                )}
                {/* <TutorialsYammer
                  tutorialName={tutorialsDescription.name}
                  tutorialUniqueCode={tutorialsDescription.uniqueCode}
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsDetailPage;
